import { RoomSettings, UserPermission } from '../../api-client/types';
import { SignalStage } from '.';
import { SubscriptionTypes } from '../../tv-auth';
import { RestrictionTypes } from '../../tv-auth/platforms';

export enum ContentState {
    Playing = 0,
    Paused = 1,
    Stopped = 2,
    Buffering = 3,
    Ended = 4,
}

export enum CamMicState {
    Disabled = 1,
    Enabled = 2,
    Paused = 3,
}

export enum CamMode {
    Standard = 0,
    Studio = 1,
}

export enum QualityIndicatorType {
    PublisherNetwork = 'publisherNetwork',
    PublisherCPU = 'publisherCPU',
    SubscriberNetwork = 'subscriberNetwork',
}

export interface ParticipantUser {
    name: string;
    avatarURL: string;
}

export interface ParticipantParams extends ParticipantUser {
    id: string;
    guestID: string;
    replaceParticipantID: string;
    bypassQueue: boolean;
    ghost: boolean;
}

export interface ContentStreamMetadata {
    userID: string;
    name: string;
    platform: string;
    url: string;
    thumbnailURL: string;
    width: number;
    height: number;
    id: string;
    restrictionType: RestrictionTypes;
    validSubTypes: SubscriptionTypes[];
}

export interface Resolution {
    width: number;
    height: number;
}

export interface Vote {
    senderID: string;
    receiverID: string;
    up: boolean;
    timestamp?: number;
    duration?: number;
}

export interface VoteState extends Vote {
    upVotes: { [key: string]: Vote[] }; // Map keys are receiver IDs
    downVotes: { [key: string]: Vote[] }; // Map keys are receiver IDs
}

export interface VoteStreakState {
    winners: { [key: string]: number };
    losers: { [key: string]: number };
    participant?: Participant;
    winner?: boolean;
}

export interface ParticipantCam {
    state: CamMicState;
    mode: CamMode;
    timestamp?: number;
}

export interface ParticipantMic {
    state: CamMicState;
    timestamp?: number;
}

export interface Participant {
    id: string;
    userID?: string;
    name: string;
    avatarURL?: string;
    camState: ParticipantCam;
    micState: ParticipantMic;
    permissions: UserPermission[];
    dupeUser: boolean;
    ghost: boolean;
    connectedAt: number;
    queuePos: number;
    tvAuthPreview: number;
}

export interface QualityIndicatorState {
    [QualityIndicatorType.PublisherCPU]: boolean;
    [QualityIndicatorType.PublisherNetwork]: boolean;
    [QualityIndicatorType.SubscriberNetwork]: boolean;
}

export interface RoomState {
    participants: { [participantID: string]: Participant };
    userParticipantIDs: { [userID: string]: Participant['id'] };
    contentState: ContentState;
    qualityIndicatorState?: QualityIndicatorState;
    contentStreamMetadataState: ContentStreamMetadata;
    voteStreakState: VoteStreakState;
    voteState: VoteState;
    settings: RoomSettings;
    stage: SignalStage;
    muted: { [key: string]: void };
    maxSubs: number;
    subsQueueSize: number;
}

export interface ChatTextMessage {
    id: string;
    timestamp: number;
    userID?: string;
    avatarURL?: string;
    participantID: string;
    participantName: string;
    body: string;
}

export interface ChatReaction {
    id: string;
    timestamp: number;
    participantID: string;
    participantName: string;
    messageID: string;
    up: boolean;
}

export interface JoinConfig {
    noPublish: boolean;
    noSubscribe: boolean;
}
